@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .searchbtn {
    @apply absolute top-1/2 right-1 -translate-y-1/2 w-fit mt-0;
  }
  .Anime {
    @apply animate-fade-left animate-infinite animate-duration-[2001ms] animate-delay-[499ms] animate-ease-in animate-reverse animate-fill-forwards;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-image: url("../src/assets/images/bg-tablet-pattern.svg");
  background-repeat: no-repeat;
  background-size: 800px;
  background-position: 90% -25%;
}

#cta {
  background-image: url("./assets/images/bg-simplify-section-desktop.svg");
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
  body {
    background-position: 50px -50px;
    background-size: 500px;
  }

  #cta {
    background-image: url("./assets/images/bg-simplify-section-mobile.svg");
  }
}
/* For mobile screens */
@media screen and (max-width: 768px) {
  :where(.css-dev-only-do-not-override-1k979oh).ant-slider .ant-slider-handle::after {
    width: 20px;
    height: 20px;
    margin-top: -3px;
  }
}

/* For medium screens and above */
@media screen and (min-width: 769px) {
  :where(.css-dev-only-do-not-override-1k979oh).ant-slider .ant-slider-handle::after {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
}

.ant-radio-inner,
.ant-radio-inner:after {
  border-radius: 0 !important;
}
.ant-radio-inner:after {
  border: none !important;
}
